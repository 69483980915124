import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
// import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'prefix' },
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'Home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]  },
    { path: 'Signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
    { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: 'appointmentletter/:id', loadChildren: () => import('./Appointment Letter/appointmentletter/appointmentletter.module').then(m => m.AppointmentletterModule) },
    { path: 'ExperienceLetter/:id', loadChildren: () => import('./expleter/expleter.module').then(m => m.ExpleterModule) },
    { path: 'Consultatntappointmentletter/:id', loadChildren: () => import('./consultantappoinmentletter/consultantappoinmentletter.module').then(m => m.ConsultantappoinmentletterModule) },
    { path: 'InternExperienceLetter/:id', loadChildren: () => import('./interexperianceletter/interexperianceletter.module').then(m => m.InterexperianceletterModule) },
    { path: 'TerminationLetter/:id', loadChildren: () => import('./terminationletter/terminationletter.module').then(m => m.TerminationletterModule) },
    { path: 'downloadsalaryslip/:id/:id1/:id2', loadChildren: () => import('./salary-slip-download/salary-slip-download.module').then(m => m.SalarySlipDownloadModule) },
    { path: 'tds-salary-slips/:id/:id1/:id2', loadChildren: () => import('./tds-salaryslip-download/tds-salaryslip-download.module').then(m => m.TdsSalaryslipDownloadModule) },
    { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) },
    { path: 'expire-change-password', loadChildren: () => import('./expire-change-password/expire-change-password.module').then(m => m.ExpireChangePasswordModule) },

    { path: 'DSFeedback', loadChildren: () => import('./dsfeedback/dsfeedback.module').then(m => m.DsfeedbackModule) },
    { path: 'AppreciationCertificate/:id', loadChildren: () => import('./DS certificate/appreciationcertificate/appreciationcertificate.module').then(m => m.AppreciationcertificateModule) },
    { path: 'ParticipationCertificate/:id', loadChildren: () => import('./DS certificate/participationcertificate/participationcertificate.module').then(m => m.ParticipationcertificateModule) },
    { path: 'InactiveSalarySlipDownload/:id/:id1/:id2', loadChildren: () => import('./inactivesalslipdownload/inactivesalslipdownload.module').then(m => m.InactivesalslipdownloadModule) },
    //{ path: 'ATLRegistration', loadChildren: () => import('./atalregistraionform/atalregistraionform.module').then(m => m.ATALregistraionformModule) },
    //{ path: 'ATLParticipantsDetail/:id', loadChildren: () => import('./addatalparticipentdetail/addatalparticipentdetail.module').then(m => m.AddatalparticipentdetailModule) },
      { path: 'ATLParticipationCertificate/:id', loadChildren: () => import('./atalparticipationcertificate/atalparticipationcertificate.module').then(m => m.AtalparticipationcertificateModule) },

	{ path: 'CalSchoolReport/:id/:id1', loadChildren: () => import('./calschoolreport/calschoolreport.module').then(m => m.CalschoolreportModule) },
    { path: 'CalCenterReport/:id/:id1', loadChildren: () => import('./calcenterreport/calcenterreport.module').then(m => m.CalcenterreportModule) },
    { path: 'CitywiseGraphicalReport', loadChildren: () => import('./citywisegraphicalreport/citywisegraphicalreport.module').then(m => m.CitywisegraphicalreportModule) },
    { path: 'StatewiseGraphicalReport', loadChildren: () => import('./statewisegraphicalreport/statewisegraphicalreport.module').then(m => m.StatewisegraphicalreportModule) },
    { path: 'PartnerwiseGraphicalReport', loadChildren: () => import('./partnerwisegraphicalreport/partnerwisegraphicalreport.module').then(m => m.PartnerwisegraphicalreportModule) },
    { path: 'PartnerPlanVsActualDetails/:id', loadChildren:()=> import('./PartnerPlanVsActual/partnerpvsadetails/partnerpvsadetails.module').then(m => m.PartnerpvsadetailsModule) },
	{ path: 'FeedbackGraphicalReport', loadChildren: () => import('./feedbackgraphicalreport/feedbackgraphicalreport.module').then(m => m.FeedbackgraphicalreportModule) },
    { path: 'ComparisonGraph', loadChildren: () => import('./comparisongraph/comparisongraph.module').then(m => m.ComparisongraphModule) },

    { path: 'DSCertificate/:id/:id1/:id2/:id3/:id4', loadChildren: () => import('./dscertificate/dscertificate.module').then(m => m.DscertificateModule) },
    { path: 'IDIDownloadCertificate/:id/:id1', loadChildren: () => import('./idicertificate/idicertificate.module').then(m => m.IdicertificateModule) },
	{ path: 'SoftSkillFeedbackForm' , loadChildren: () => import('./feedbackform/feedbackform.module').then(m => m.FeedbackformModule)},
	{ path: 'AssessmentOverview' , loadChildren:() => import('./assessmentoverviewcomparision/assessmentoverviewcomparision.module').then(m => m.AssessmentoverviewcomparisionModule)},
	{ path: 'CompetencywiseGraphicalReport' , loadChildren:() => import('./compentencywisegraphicalreport/compentencywisegraphicalreport.module').then(m => m.CompentencywisegraphicalreportModule)},
	{ path: 'ViewTLPaymentReciept/:id/:id1/:id2/:id3/:id4', loadChildren: () => import('./layout/center/CAl-PIF/Billing/viewtlpaymentreciept/viewtlpaymentreciept.module').then(m => m.ViewtlpaymentrecieptModule) },
	{ path: 'IncrementLetter/:id', loadChildren: () => import('./incrementletter/incrementletter.module').then(m => m.IncrementletterModule) },
	{ path: 'ViewInvoiceReciept/:id/:id1/:id2', loadChildren: () => import('./layout/center/CAl-PIF/Billing/Center Invoice List/viewinvoicereciept/viewinvoicereciept.module').then(m => m.ViewinvoicerecieptModule) },
	{ path: 'ATALStatewiseGraphicalReport', loadChildren: () => import('./layout/center/ATAL/Graphical Reports/atalstatewisegraphreport/atalstatewisegraphreport.module').then(m => m.AtalstatewisegraphreportModule) },
    { path: 'ATALCitywiseGraphicalReport', loadChildren: () => import('./layout/center/ATAL/Graphical Reports/atalcitywisegraphreport/atalcitywisegraphreport.module').then(m => m.AtalcitywisegraphreportModule) },
    { path: '**', redirectTo: 'not-found' }
	


];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],


})
export class AppRoutingModule { }
