import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/shared/services/student.service';

export class DigitalSaksharForm {

  public digitalclassroomno : string = '';
  public teacherusingdsclassroom : string = '';
  public teacherusingdsclassroomtypereason : string = '';
  public classaccordingdstt : string = '';
  public whichclassttrunning : string = '';
  public whichsubjectteaching : string = '';
  public whichtopicteaching : string = '';
  public classaccordingdsttypereason : string = '';
  public useofvisitds : string = '';
  public useofvisitdsreason : string = '';
  public dsmethod : string = '';
}

@Component({
  selector: 'app-updatedigitalsakshardialog',
  templateUrl: './updatedigitalsakshardialog.component.html',
  styleUrls: ['./updatedigitalsakshardialog.component.scss']
})

export class UpdatedigitalsakshardialogComponent implements OnInit {

  loading =false;
  userid;
  digitalSakshar:FormGroup;
  q17;
  q18;
  q19;
  q20;
  q21;
  q22;
  q23;
  q24;
  q25;
  q26;
  q27;
  formData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,public dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService,private service:StudentService,private router:Router) {

    this.userid = localStorage.getItem("UserId");
    this.formData = data;
  }

  ngOnInit() {
    
    this.q17 = this.formData.digitalclassroomno;
    this.q18 = this.formData.teacherusingdsclassroom;
    this.q19 = this.formData.teacherusingdsclassroomtypereason;
    this.q20 = this.formData.classaccordingdstt;
    this.q21 = this.formData.whichclassttrunning;
    this.q22 = this.formData.whichsubjectteaching;
    this.q23 = this.formData.whichtopicteaching;
    this.q24 = this.formData.classaccordingdsttypereason;
    this.q25 = this.formData.useofvisitds;
    this.q26 = this.formData.useofvisitdsreason;
    this.q27 = this.formData.dsmethod;

    this.digitalSakshar = this.fb.group({
      digitalclassroomno: [''],
      teacherusingdsclassroom: [''],
      teacherusingdsclassroomtypereason: [''],
      classaccordingdstt: [''],
      whichclassttrunning: [''],
      whichsubjectteaching: [''],
      classaccordingdsttypereason: [''],
      useofvisitds: [''],
      useofvisitdsreason: [''],
      whichtopicteaching: [''],
      dsmethod: [''],
    });

  }

  clearControll(){
    
    this.q19=null;
    this.q20=null;
    this.q21=null;
    this.q22=null;
    this.q23=null;
    this.q24=null;
    this.q25=null;
    this.q26=null;

    const teacherusingdsclassroomtypereason = this.digitalSakshar.get('teacherusingdsclassroomtypereason');
    teacherusingdsclassroomtypereason.clearValidators();
    teacherusingdsclassroomtypereason.updateValueAndValidity();
    
    const classaccordingdstt = this.digitalSakshar.get('classaccordingdstt');
    classaccordingdstt.clearValidators();
    classaccordingdstt.updateValueAndValidity();

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    classaccordingdsttypereason.clearValidators();
    classaccordingdsttypereason.updateValueAndValidity();
   
    const useofvisitds = this.digitalSakshar.get('useofvisitds');
    useofvisitds.clearValidators();
    useofvisitds.updateValueAndValidity();
    
    const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    useofvisitdsreason.clearValidators();
    useofvisitdsreason.updateValueAndValidity();

  }

  clearControll_1(){
    
    this.q18='No';
    this.q20=null
    this.q21=null;
    this.q22=null;
    this.q23=null;
    this.q24=null;
    this.q25=null;
    this.q26=null;

    const teacherusingdsclassroom = this.digitalSakshar.get('teacherusingdsclassroom');
    teacherusingdsclassroom.clearValidators();
    teacherusingdsclassroom.updateValueAndValidity();
    
    const classaccordingdstt = this.digitalSakshar.get('classaccordingdstt');
    classaccordingdstt.clearValidators();
    classaccordingdstt.updateValueAndValidity();

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    classaccordingdsttypereason.clearValidators();
    classaccordingdsttypereason.updateValueAndValidity();

    const useofvisitds = this.digitalSakshar.get('useofvisitds');
    useofvisitds.clearValidators();
    useofvisitds.updateValueAndValidity();

    const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    useofvisitdsreason.clearValidators();
    useofvisitdsreason.updateValueAndValidity();
  }

  clearControl2(){

    this.q20 = 'No';
    this.q21=null;
    this.q22=null;
    this.q23=null;

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

  }

  clearControl2_(){
    
    this.q20='Yes';
    this.q24=null;
    const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    classaccordingdsttypereason.clearValidators();
    classaccordingdsttypereason.updateValueAndValidity();
  }

  clearControl3(){
    
    this.q25='No';
    this.q21=null;
    this.q22=null;
    this.q23=null;

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

  }

  clearControl4(){

    this.q25='Yes';
    this.q26=null;
    const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    useofvisitdsreason.clearValidators();
    useofvisitdsreason.updateValueAndValidity();
  }

  update(){
  
    const data: DigitalSaksharForm = new DigitalSaksharForm();
    data.digitalclassroomno = this.q17;
    data.teacherusingdsclassroom = this.q18;
    data.teacherusingdsclassroomtypereason = this.q19;
    data.classaccordingdstt = this.q20;
    data.whichclassttrunning = this.q21 ;
    data.whichsubjectteaching = this.q22;
    data.whichtopicteaching = this.q23;
    data.classaccordingdsttypereason = this.q24;
    data.useofvisitds = this.q25;
    data.useofvisitdsreason = this.q26;
    data.dsmethod = this.q27;
    
    this.service.UpdateActionPointerMyEschool(this.formData.actionid,this.userid,data).subscribe((res)=>{
      
      if (res == 'Success') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        window.location.reload();
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
      }
    });
  }

  clear(){
    this.dialog.closeAll();
  }
}
