import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class Employee {
  public date: string = '';
  public startlog: string = '';
  public endlog: string = '';
  public empid: string = '';
}

@Component({
  selector: 'app-updateattendancepopup',
  templateUrl: './updateattendancepopup.component.html',
  styleUrls: ['./updateattendancepopup.component.scss']
})
export class UpdateattendancepopupComponent implements OnInit {
  Updatedby;
  ActiveForm: FormGroup;
  empid;
  empname;
  date;
  loading;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, private fb: FormBuilder, private toastr: ToastrService, private router: Router, private dialog: MatDialog) {
    this.Updatedby = localStorage.getItem("UserId")
    this.empname = data.empname;
    this.empid = data.empid;
    this.date = data.date;
  }

  ngOnInit() {
    this.ActiveForm = this.fb.group({
      markedin: ['', Validators.required],
      markedout: ['', Validators.required],
    })
  }

  ChangeStatus() {
    this.loading = true;
    const data: Employee = new Employee();
    data.empid = this.empid;
    data.date = this.date;
    data.startlog = this.ActiveForm.value.markedin + ":00";
    data.endlog = this.ActiveForm.value.markedout + ":00";


    this.service.AddAbsentEmployeeAttendance(this.Updatedby, data).subscribe((res: string) => {
      if (res == 'Success') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        this.toastr.success('Changes Updated Sucessfully!')
        // this.router.navigate(['/AbsentEmployeeAttendanceList']);
        this.router.navigateByUrl('/Employee', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/AbsentEmployeeAttendanceList']));
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
      }
    })
  }

  clear() {
    this.dialog.ngOnDestroy();
  }

}
