import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface ApiResponse {
  message: string;
  add: string;
  fail: string;
}

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient) { }

  // GetStudentList(where) {
  //   return this.http.get(environment.apiUrl+"Student/" + "GetStudentList?whr="  + where )
  // }
  GetStudentList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  InsertStudent(data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertStudent', data);
  }

  GetStudentDetail(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentDetail/' + studentid);
  }
  UpdateStudent(studentid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateStudent/' + studentid, data);
  }
  GetStudentAttendnaceList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAttendnaceList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentAttendance(searchdate, date, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAttendance?attdate=' + btoa(searchdate) + '&date=' + btoa(date) + '&whr=' + btoa(where));
  }
  AddStudentAttendance(dsbatch: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddStudentAttendance/' + dsbatch + '/' + userid, data);
  }
  GetStudentAttendanceDetail(attdate, debatch, createby, role) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAttendanceDetail?attdate=' + attdate + '&batchid=' + debatch + '&createby=' + createby + '&userole=' + role);

  }
  UpdateStudentAttendance(debatch: string, createby: string, attid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateStudentAttendance/' + debatch + '/' + createby + '/' + attid, data);
  }
  GetStudentCourse(where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentCourse?whr=' + btoa(where));
  }
  AddStudentCourse(data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddStudentCourse/', data);
  }
  UpdateStudentCourse(studentid: string, studcourseid: string, data) {

    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateStudentCourse/' + studentid + '/' + studcourseid, data);
  }
  UpdateStudentIDI(studentid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateStudentIDI/' + studentid, data);
  }
  InsertIDIStudent(data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertIDIStudent', data);
  }
  GetStudentIDIList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentIDIList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentIDIDetail(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentIDIDetail/' + studentid);
  }
  InsertIDIStudentQualification(studentid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertIDIStudentQualification/' + studentid, data);
  }

  GetIDIStudentQualification(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDIStudentQualification/' + studentid);
  }
  RemoveIDIStudentQualification(quliid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'RemoveIDIStudentQualification/' + quliid);
  }

  InsertIDIStudentLanguage(studentid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertIDIStudentLanguage/' + studentid, data);
  }

  GetIDIStudentLanguage(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDIStudentLanguage/' + studentid);
  }

  RemoveIDIStudentLnaguage(stlangid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'RemoveIDIStudentLnaguage/' + stlangid);
  }

  AddStudentFamily(userid, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddStudentFamily?userid=' + userid, data);
  }

  GetStudentFamilyDetail(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentFamilyDetail/' + studentid);
  }


  GetStudentCourseDetail(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentCourseDetail/' + studentid);
  }

  RemoveStudentCourse(studcourseid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'RemoveStudentCourse/' + studcourseid);
  }

  AddStudentFee(userid, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddStudentFee?userid=' + userid, data);
  }

  GetStudentFeesDetail(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentFeesDetail/' + studentid);
  }

  RemoveStudentFees(studcourseid: string, studfeeid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'RemoveStudentFees/' + studcourseid + '?studfeeid=' + studfeeid);
  }

  GetStudentIDIReport(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentIDIReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetStandardBatch(pgid: string, schoolid: string, std: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStandardBatch/' + pgid + '/' + schoolid + '/' + std);
  }

  GetStudentCALReport(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentCALReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  AddCandidates(userid: string, data) {

    return this.http.post(environment.apiUrl + 'Student/' + 'AddCandidates/' + userid, data);
  }
  GetInterestedStudentYes(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetInterestedStudentYes/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetPendingJobSeeker(where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetPendingJobSeeker?whr=' + btoa(where));
  }

  FillDropDown1(tablename, column1, column2, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'FillDropDown1?tablename=' + tablename + '&column1=' + column1 + '&column2=' + column2 + '&whr=' + btoa(where));
  }

  UpdateCandidatesPending(userid: string, data) {

    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateCandidatesPending/' + userid, data);
  }

  GetRptStudentAttendanceBatchwise(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentAttendanceBatchwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptStudentAttendance(userid: string, role: string, pgid: string, whr: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetUploadStudentDetail(schoolid: string, createby: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetUploadStudentDetail/' + schoolid + '/' + createby);
  }
  UpdateUploadStudentDetailNew(schoolid: string, createby: string, data: any) {
    return this.http.post<ApiResponse>(environment.apiUrl + 'Student/' + 'UpdateUploadStudentDetailNew/' + schoolid + '/' + createby, data);
  }
  UploadStudent(schoolid: string, academicyear: string, userid: string, data: any) {

    return this.http.post(environment.apiUrl + 'Student/' + 'UploadStudent/' + schoolid + '/' + academicyear + '/' + userid, data);
  }

  GetStudentReportATAL(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentReportATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentReportUBS(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentReportUBS/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }



  GetStudentListATAL(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentListATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetStudentListUBS(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentListUBS/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptCALWeeklyAttedance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALWeeklyAttedance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptATALWeeklyAttedance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALWeeklyAttedance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptIDIStudentAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptIDIStudentAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptCALStudentAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALStudentAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALSchoolAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALSchoolAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  IDIStudentEnrollentReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'IDIStudentEnrollentReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetStudentsForExport(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentsForExport/' + schoolid);
  }
  UpdateUploadStudentCAL(updateby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateUploadStudentCAL/' + updateby, data);
  }


  UploadUpdateStudent(schoolid: string, userid: string, data: any) {

    return this.http.post(environment.apiUrl + 'Student/' + 'UploadUpdateStudent/' + schoolid + '/' + userid, data);
  }


  GetUploadUpdateStudentDetail(schoolid: string, createby: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetUploadUpdateStudentDetail/' + schoolid + '/' + createby);
  }

  GetRptMonthwiseEnrollmentCAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMonthwiseEnrollmentCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptCALSchoolWeekAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALSchoolWeekAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptAssessmentUsers(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAssessmentUsers/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentNotification(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALAssessmentNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDIAssessmentNotification(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDIAssessmentNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptGenderwiseEnrollment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptGenderwiseEnrollment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  MonthlyStudentDataCount(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMonthlyStudentDataCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCALSchoolAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALSchoolAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment5(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment8(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment9(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessment12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessment12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  // ATAL Monthwiseenrolment summary

  GetRptMonthwiseEnrollmentATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMonthwiseEnrollmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }



  // ATAL GradewiseAttendannce Report
  GetRptATALStudentAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALStudentAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptATALSchoolAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALSchoolAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptCALAssessmentATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment8(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment9(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessment12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessment12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolAssessmentATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSchoolAssessmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  // ATAL Participant

  GetTechnovatorsParticipantsList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTechnovatorsParticipantsList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptTechnovatorsParticipants(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptTechnovatorsParticipants/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetTechnovatorsParticipantsDetail(regid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTechnovatorsParticipantsDetail/' + regid);
  }

  InsertATALParticipation(data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertATALParticipation', data);
  }
  GetATALParticipationDetail(regid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALParticipationDetail/' + regid);
  }
  GetATALParticipationotherDetail(regid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALParticipationotherDetail/' + regid);
  }
  AddATALParticipationDetail(rgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddATALParticipationDetail/' + rgid, data);
  }
  GetRptATALParticipationPendingStudent(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALParticipationPendingStudent/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCALDashSchoolList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALDashSchoolList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALDashAllSchoolList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALDashAllSchoolList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  // ATAL Student
  UploadATALStudent(schoolid: string, academicyear: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UploadATALStudent/' + schoolid + '/' + academicyear + '/' + userid, data);
  }

  GetUploadATALStudentDetail(schoolid: string, createby: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetUploadATALStudentDetail/' + schoolid + '/' + createby);
  }

  UpdateUploadATALStudentDetail(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateUploadATALStudentDetail/' + createby, data);
  }

  UpdateUploadStudentATAL(updateby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateUploadStudentATAL/' + updateby, data);
  }

  UploadUpdateStudentATAL(schoolid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UploadUpdateStudentATAL/' + schoolid + '/' + userid, data);
  }

  GetUploadUpdateStudentDetailATAL(schoolid: string, updateby: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetUploadUpdateStudentDetailATAL/' + schoolid + '/' + updateby);
  }
  GetCALCompAssessmentFinal10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCyberSmartUsers(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCyberSmartUsers/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  // schoolwise Time Table

  GetTimeTableMasterList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTimeTableMasterList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetMyEschoolMasterTTList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolMasterTTList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  RemoveTimeTableMasterDetails(ttdid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'RemoveTimeTableMasterDetails/' + ttdid);
  }
  UpdateSchoolwiseTimeTableDetail(ttmid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateSchoolwiseTimeTableDetail?ttmid=' + ttmid + '&createby=' + createby, data);
  }

  GetTimeTableDetailsList(ttmid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTimeTableDetailsList/' + ttmid);
  }
  MyESchoolGetTimeTableDetailsList(ttmid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'MyESchoolGetTimeTableDetailsList/' + ttmid);
  }

  GetPlanTimeTableDetails(pmid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetPlanTimeTableDetails/' + pmid);
  }
  GetMyESchoolPlanTimeTableDetails(pmid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyESchoolPlanTimeTableDetails/' + pmid);
  }
  GetschoolwisePlanTTList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetschoolwisePlanTTList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetMyESchoolwisePlanTTList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyESchoolwisePlanTTList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  AddIDIStudentAttendance(date: Date, batchid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddIDIStudentAttendance?date=' + date + '&batchid=' + batchid + '&createby=' + createby, data);
  }

  GetRptMonthlyStudentAttendanceIDI(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMonthlyStudentAttendanceIDI/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetStudentsForAttendance(schoolid: string, month: string, year: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentsForAttendance/' + schoolid + '/' + month + '/' + year);
  }

  GetRptCyberSmartPlanVsActualCount(whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCyberSmartPlanVsActualCount?whr=' + btoa(whr));
  }
  // 3 remaning report 
  GetRptAttendanceSchoolWiseCAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCAL/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetRptAttendanceSchoolWiseCALSancharak(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCALSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceCenterWiseCAL(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceCenterWiseCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceCenterWiseCALSancharak(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceCenterWiseCALSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceGradewiseCAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseCALSancharak(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCALSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceStudWiseCAL(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceStudWiseCALSancharak(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCALSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetCALCompAssessmentFinal5(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal8(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal9(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  // Competency wise Standard Final Reports
  GetATALCompAssessmentFinal6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal6/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetATALCompAssessmentFinal7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal7/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  UploadStudentDOC(studentid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Student/' + 'UploadStudentDOC/' + studentid, formData);
  }

  GetStudentDOC(studentid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentDOC/' + studentid);
  }

  GetCALFinalAssessmentLevelwise(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALFinalAssessmentLevelwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetATALCompAssessmentFinal10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessmentFinal11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessmentFinal12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetATALCompAssessmentFinal8(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompAssessmentFinal9(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALCompAssessmentFinal9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal1/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal2/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFinal4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFinal4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCompAssessmentFCBeginner1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCompAssessmentFCBeginner2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCompAssessmentFCBeginner3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCompAssessmentFCBeginner4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCompAssessmentFCBeginner5(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptGenderwiseEnrollmentSancharak(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptGenderwiseEnrollmentSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptOverallBeneficiariesIDI(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptOverallBeneficiariesIDI/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  GetTimeTableMasterDetails(ttmid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTimeTableMasterDetails/' + ttmid);
  }
  GetMyEschoolMasterTTDetail(ttmid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolMasterTTDetail/' + ttmid);
  }
  GetCALCompAssessmentFCFinal6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal8(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal9(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCompAssessmentFCFinal12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddNewSchoolwiseTimeTableDetail(ttmid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddNewSchoolwiseTimeTableDetail?ttmid=' + ttmid + '&createby=' + createby, data);
  }
  GetRptIDIAgewiseStudentEnrollment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptIDIAgewiseStudentEnrollment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATLSchoolModuleData(academicyear: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATLSchoolModuleData/' + academicyear);
  }
  AddIDISocialMediaSubscription(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddIDISocialMediaSubscription/' + createby, data);
  }
  GetStudentSocialMediaSubscription(whr: any) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentSocialMediaSubscription?whr=' + btoa(whr));
  }
  GetIDIStudentSocialMediaDetail(batchid: string, createby: string, userole: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDIStudentSocialMediaDetail?batchid=' + batchid + '&createby=' + createby + '&userole=' + userole);
  }
  UpdateStudentSocialMedia(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateStudentSocialMedia/' + createby, data);
  }
  GetSocialMediaSubscriptionList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSocialMediaSubscriptionList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptSocialMediaSubscription(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptSocialMediaSubscription/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  GetCALCompAssessmentEnglishFinal1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentEnglishFinal1/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentEnglishFinal2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentEnglishFinal2/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentEnglishFinal3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentEnglishFinal3/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentEnglishFinal4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentEnglishFinal4/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentMarathiFinal1(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentMarathiFinal1/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentMarathiFinal2(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentMarathiFinal2/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentMarathiFinal3(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentMarathiFinal3/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentMarathiFinal4(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentMarathiFinal4/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentHindiFinal1(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentHindiFinal1/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentHindiFinal2(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentHindiFinal2/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentHindiFinal3(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentHindiFinal3/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentHindiFinal4(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentHindiFinal4/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCompAssessmentFCBeginner6to12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCompAssessmentFCBeginner6to12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentFCFinal2(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentFCFinal3(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentFCFinal4(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  GetCALCompAssessmentFCFinal5(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALCompAssessmentFCFinal1(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCompAssessmentFCFinal1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetStudentListMySchool(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentListMySchool/' + userid + '/' + role + '/' + pgid + '?whr=' + where);
  }


  //add api
  CheckStudentAttendanceCAL(schoolid: string, standard: string, batch: string, month: string, year: string, day: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'CheckStudentAttendanceCAL/' + schoolid + '/' + standard + '/' + batch + '/' + month + '/' + year + '/' + day);
  }

  // update api 
  AddActualStudentCAL(date: Date, standard: string, batchid: string, createby: string, strday: string, pgid: string, pdid: string, pmid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddActualStudentCAL?date=' + date + '&standard=' + standard + '&batchid=' + batchid + '&createby=' + createby + '&strday=' + strday + '&pgid=' + pgid + '&pdid=' + pdid + '&pmid=' + pmid, data);
  }
  GetStudentAssignmentDetailListCAL(schoolid: string, month: string, year: string, std: string, batchid: string, day: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAttedanceDetailListCAL/' + schoolid + '/' + month + '/' + year + '/' + std + '/' + batchid + '/' + day);
  }

  InsertPlanTimeTableMaster(schoolid: string, centid: string, createby: string, month: string, year: string, timetableid: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertPlanTimeTableMasterNew/' + schoolid + '/' + centid + '/' + createby + '/' + month + '/' + year + '/' + timetableid + '/' + pgid, data);
  }
  InsertMyESchoolPlanTimeTableMaster(schoolid: string, centid: string, createby: string, month: string, year: string, timetableid: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertMyESchoolPlanTimeTableMaster/' + schoolid + '/' + centid + '/' + createby + '/' + month + '/' + year + '/' + timetableid + '/' + pgid, data);
  }

  InsertTimeTableMaster(schoolid: string, centid: string, createby: string, academicyear: string, ttno: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertTimeTableMaster/' + schoolid + '/' + centid + '/' + createby + '/' + academicyear + '/' + ttno + '/' + pgid, data);
  }
  InsertMyESchoolTimeTableMaster(schoolid: string, centid: string, createby: string, academicyear: string, ttno: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertMyESchoolTimeTableMaster/' + schoolid + '/' + centid + '/' + createby + '/' + academicyear + '/' + ttno + '/' + pgid, data);
  }

  GetRptStudentMyschool(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentMyschool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCALAssessmentNotification1to4Lang(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALAssessmentNotification1to4Lang/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetCALAssessmentNotification1to4Math(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALAssessmentNotification1to4Math/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudAttendanceNotification(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudAttendanceNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALSchoolAssessment1to4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALSchoolAssessment1to4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  CheckAdhaar(adharnumber: string) {
    console.log(adharnumber);

    return this.http.get(environment.apiUrl + 'Student/' + 'CheckAdhaar/' + adharnumber)
  }

  GetCALSchoolAssessment1to4Midline(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALSchoolAssessment1to4Midline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALEndlineCompAssessment10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALEndlineCompAssessment10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetATALEndlineCompAssessment12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetATALEndlineCompAssessment12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetSchoolAssessmentATALEndline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSchoolAssessmentATALEndline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetStudentCALReportSancharak(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentCALReportSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptATALSCHMonthlyStudentDataCount(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALSCHMonthlyStudentDataCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetSchoolAssessmentATAL2022(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSchoolAssessmentATAL2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptCALAssessmentATAL2022(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessmentATAL2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptIDIStudentAttendance2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptIDIStudentAttendance2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIStudentAttendance2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptIDIStudentAttendance2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessment2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessment2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessment2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessment2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALSchoolAssessmentNew(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALSchoolAssessmentNew/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseCAL2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCAL2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseCALSancharak2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCALSancharak2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceStudWiseCAL2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCAL2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptStudentEgradeAtt(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentEgradeAtt/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceStudWiseCALSancharak2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCALSancharak2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceSchoolWiseCALNew(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCALNew/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetRptAttendanceSchoolWiseCALSancharakNew(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCALSancharakNew/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceCenterWiseCALOLD(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceCenterWiseCALOLD/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAttendanceCenterWiseCALSancharakOLD(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceCenterWiseCALSancharakOLD/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptCALSchoolGradeWiseAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALSchoolGradeWiseAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlineAssessmentNotification(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlineAssessmentNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAssessmentNotificationIDI(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetAssessmentNotificationIDI/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentNotificationBaseline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALAssessmentNotificationBaseline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetDropoutStudentReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetDropoutStudentReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetNewEntryStudentRpt(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetNewEntryStudentRpt/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyESchoolAssessmentLevelSchool(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyESchoolAssessmentLevelSchool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptMyESchoolAssessmentLevelStudent(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyESchoolAssessmentLevelStudent/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddExtraLectureAttendanceNB(schoolid, empid: string, standard: string, batchid: string, division: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddExtraLectureAttendanceNB/' + schoolid + '/' + empid + '/' + standard + '/' + batchid + '/' + division, data);
  }
  AddExtraLectureAttPending(schoolid: string, standard: string, batchid: string, division: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddExtraLectureAttPending/' + schoolid + '/' + standard + '/' + batchid + '/' + division, data);
  }

  GetRptAttendanceCenterWiseCAL2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceCenterWiseCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceSchoolWiseCAL2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseCAL2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceStudWiseCAL2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptStudentEgradeAtt2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentEgradeAtt2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceSchoolWiseCALSancharak2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceSchoolWiseCALSancharak2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseCALSancharak2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseCALSancharak2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceStudWiseCALSancharak2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseCALSancharak2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceStudWiseNB(month: string, year: string, userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseNB/' + month + '/' + year + '/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPlanTimeTableBatchNotification(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetPlanTimeTableBatchNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  MonthlyStudAttendanceDetailCal(schoolid: string, month: string, year: string, userid: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Student/' + 'MonthlyStudAttendanceDetailCal/' + schoolid + '/' + month + '/' + year + '/' + userid, data);
  }
  RptMyEschoolTeacherTrainingAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'RptMyEschoolTeacherTrainingAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadMESTeacherTrainingAttendance(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadMESTeacherTrainingAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertMyESchoolTeacherTrainingAttendance(schoolid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertMyESchoolTeacherTrainingAttendance/' + schoolid + '/' + userid, data);
  }
  GetRptAserEnglishSchoolwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAserEnglishSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetStateWiseAllSummaryMyEschool(pgid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStateWiseAllSummaryMyEschool/' + pgid);
  }
  GetCityWiseAllSummaryMyEschool(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCityWiseAllSummaryMyEschool/' + userid + '/' + role + '/' + pgid);
  }
  GetMyEschoolTeacherList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolTeacherList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSkillingWorkshopList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSkillingWorkshopList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPartnerWiseAllSummaryDash(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetPartnerWiseAllSummaryDash/' + userid + '/' + role + '/' + pgid);
  }
  GetMyEschoolTeacherTrainingAttendance(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolTeacherTrainingAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCityWiseAllSummaryEnglish(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCityWiseAllSummaryEnglish/' + userid + '/' + role + '/' + pgid);
  }
  GetCityWiseAllSummaryMindspark(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCityWiseAllSummaryMindspark/' + userid + '/' + role + '/' + pgid);
  }
  DownloadMyESchoolAserAssessment1to5(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadMyESchoolAserAssessment1to5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadMyESchoolAserAssessment6to8(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadMyESchoolAserAssessment6to8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddMyESchoolAssessment1to5(schoolid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddMyESchoolAssessment1to5/' + schoolid + '/' + userid, data);
  }
  AddMyESchoolAssessment6to8(schoolid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddMyESchoolAssessment6to8/' + schoolid + '/' + userid, data);
  }
  GetMyEschoolTeacherTrainingFeedback(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolTeacherTrainingFeedback/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddMyEschoolTeacherTrainingFeedback(userid: string, data) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddMyEschoolTeacherTrainingFeedback/' + userid, data);
  }
  GetRptMyEschoolTeacherTrainingFeedback(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyEschoolTeacherTrainingFeedback/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLivelihoodPlacementReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetLivelihoodPlacementReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLivelihoodAwarenessReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetLivelihoodAwarenessReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLivelihoodAwarenessReportSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetLivelihoodAwarenessReportSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLivelihoodCareerGuidanceReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetLivelihoodCareerGuidanceReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLivelihoodjobportalregReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetLivelihoodjobportalregReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALTLWiseNBData(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALTLWiseNBData/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptDailyAttendanceTrackingSchool(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptDailyAttendanceTrackingSchool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetMyEschoolvisitList(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolvisitList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertMyeschoolVisit(userid: string, data) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertMyeschoolVisit/' + userid, data);
  }
  UpdateMyEschoolVisit(myvid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateMyEschoolVisit/' + myvid + '/' + userid, data);
  }
  GetMyEschoolVisit(id) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolVisit/' + id);
  }
  GetRptMyEschoolVisit(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyEschoolVisit/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyESchoolAssessmentLevelSchoolReport(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyESchoolAssessmentLevelSchoolReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyESchoolAssessmentLevelStudentReport(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyESchoolAssessmentLevelStudentReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyEschoolStudentwise1to5(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyEschoolStudentwise1to5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyEschoolStudentwise6to8(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyEschoolStudentwise6to8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd5(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd6(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd7(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd8(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd9(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd10(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlinePracticalAssessment2023Gujaratstd11(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023Gujaratstd12(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023Gujaratstd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std5(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std6(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std7(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std8(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std9(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMidlinePracticalAssessment2023std10(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMidlinePracticalAssessment2023std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd5(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd6(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd7(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd8(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd9(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyStd10(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalMidlinecompetencyGujarat(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALPracticalMidlinecompetencyGujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadPracticalMidlineAssessmentCAL2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadPracticalMidlineAssessmentCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline5std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline5std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline6std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline6std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline7std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline7std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline8std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline8std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline9std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline9std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline10std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline10std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline11std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline11std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline12std2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline12std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline9stdGujarat2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline9stdGujarat2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline10stdGujarat2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline10stdGujarat2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline11stdGujarat2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline11stdGujarat2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQMidline12stdGujarat2023(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALGradewiseAssessmentMCQMidline12stdGujarat2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadMyESchoolAserAssessment1to8(userid: string, role: string, pgid, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadMyESchoolAserAssessment1to8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDITimeTableDetailsList(ttmid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDITimeTableDetailsList/' + ttmid);
  }
  InsertIDIPlanTimeTableMaster(centid: string, createby: string, month: string, year: string, timetableid: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertIDIPlanTimeTableMaster/' + centid + '/' + createby + '/' + month + '/' + year + '/' + timetableid + '/' + pgid, data);
  }
  GetcenterwisePlanTTList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetcenterwisePlanTTList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDIPlanTimeTableDetails(ipmid: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDIPlanTimeTableDetails/' + ipmid);
  }
  GetStudentAttedanceDetailListCALIDI(centid: string, month: string, year: string, batchid: string, day: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAttedanceDetailListCALIDI/' + centid + '/' + month + '/' + year + '/' + batchid + '/' + day);
  }
  CheckStudentAttendanceIDI(centid: string, batch: string, month: string, year: string, day: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'CheckStudentAttendanceIDI/' + centid + '/' + batch + '/' + month + '/' + year + '/' + day);
  }
  AddActualStudentIDI(date: Date, parentbatch: string, batchid: string, createby: string, strday: string, pgid: string, ipdid: string, ipmid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddActualStudentIDI?date=' + date + '&parentbatch=' + parentbatch + '&batchid=' + batchid + '&createby=' + createby + '&strday=' + strday + '&pgid=' + pgid + '&ipdid=' + ipdid + '&ipmid=' + ipmid, data);
  }
  GetStudentAssignmentDetailListIDI(centid: string, batchid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetStudentAssignmentDetailListIDI/' + centid + '/' + batchid);
  }
  GetIDITimeTableMasterList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetIDITimeTableMasterList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertIDITimeTableMaster(centid: string, createby: string, academicyear: string, ttno: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertIDITimeTableMaster/' + centid + '/' + createby + '/' + academicyear + '/' + ttno + '/' + pgid, data);
  }
  GetTimeTableMasterDetailsIDI(ittmid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetTimeTableMasterDetailsIDI/' + ittmid);
  }
  UpdateCenterwiseTimeTableDetail(ittmid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateCenterwiseTimeTableDetail?ittmid=' + ittmid + '&createby=' + createby, data);
  }
  AddNewCenterwiseTimeTableDetail(ittmid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'AddNewCenterwiseTimeTableDetail?ittmid=' + ittmid + '&createby=' + createby, data);
  }
  GetRptStudentWiseAttendanceIDI(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptStudentWiseAttendanceIDI/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyESchoolTwentyPercentDataSchoolwise(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptMyESchoolTwentyPercentDataSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceStudWiseEnglish2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceStudWiseEnglish2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseEnglish2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseEnglish2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGameKraftMCQEndlinecompetency2023Std9(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetGameKraftMCQEndlinecompetency2023Std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGamekraftMCQEndlinecompetency2023Std10(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetGamekraftMCQEndlinecompetency2023Std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGameKraftMCQEndlinecompetency2023Std11(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetGameKraftMCQEndlinecompetency2023Std11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGameKraftMCQEndlinecompetency2023Std12(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetGameKraftMCQEndlinecompetency2023Std12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptDailyAttendanceTrackingSchoolWithDateFilter(userid: string, role: string, pgid: string, whr: string, filterWhr: string) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptDailyAttendanceTrackingSchoolWithDateFilter/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr) + '&' + 'filterWhr=' + btoa(filterWhr));
  }
  GetEnglishCompentancyStudentwiseMidlinelevelreport(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetEnglishCompentancyStudentwiseMidlinelevelreport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishMidlineAssessmentDetailstdWise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetEnglishMidlineAssessmentDetailstdWise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishCompentancySchoolwiseMidlinelevel1report(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetEnglishCompentancySchoolwiseMidlinelevel1report/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceGradewiseIDI2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptAttendanceGradewiseIDI2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadBaselineAssessmentFoundation(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadBaselineAssessmentFoundation/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadPracticalBaselineAssessmentFoundation(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'DownloadPracticalBaselineAssessmentFoundation/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertSkillingWorkshop(createdby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertSkillingWorkshop/' + createdby, data);
  }
  GetFoundationBaselinePractical2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetFoundationBaselinePractical2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetFoundationMCQBaseline2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetFoundationMCQBaseline2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetMyESchoolInfraDetailCount(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyESchoolInfraDetailCount/' + schoolid);
  }
  GetRptComputerAssessment(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptComputerAssessment/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetMyEschoolcenterinfralist(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolcenterinfralist/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertActionPointerMyESchool(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertActionPointerMyESchool/' + userid, data);
  }

  GetActionPointerMyESchool(myvid: any) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetActionPointerMyESchool/' + myvid);
  }

  UpdateActionPointerMyEschool(myvid: any, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'UpdateActionPointerMyEschool/' + myvid + '/' + userid, data);
  }

  GetCALEndlinePracticalAssessment2023Gujaratstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023Gujaratstd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023Gujaratstd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessment2023std10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALEndlinePracticalAssessment2023std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptCALAssessmentLevelStudentPracticalEndline2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptCALAssessmentLevelStudentPracticalEndline2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQEndlinecompetency2023Std5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQEndlinecompetency2023Std12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALMCQEndlinecompetency2023Std12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetMyEschoolMasterTTReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetMyEschoolMasterTTReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  CenterInfraReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'CenterInfraReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAserComparison23(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetAserComparison23/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAserBaselineMidlineComparision2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetAserBaselineMidlineComparision2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolTeacherTrainingAttendenceList2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetSchoolTeacherTrainingAttendenceList2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
}
